import { useState } from 'react';

import './login.css';

const Login = (props) => {
	
	const [formData, setFormData] = useState({
		password: '',
		id: 'bird_killer',
		error: false
	});
	
	const formUpdate = (e) => {
		setFormData({...formData, error: false, [e.currentTarget.name]: e.currentTarget.value})
	}

	const rot13 = str => str.split('')
    .map(char => String.fromCharCode(char.charCodeAt(0) + (char.toLowerCase() < 'n' ? 13 : -13)))
    .join('');
	
	const submit = (e) => {
		e.preventDefault();
		if (rot13(formData.password).localeCompare('pngjbeq') === 0){
			props.closeLogin()
		} else {
			setFormData({...formData, error: 'Wrong password :('})
		}
	}
	
	return (
		<div className='login__root'>
			<p className='login__systemname'>moss operating system version 000.002bf5</p>
			<div className={'login__container' + (formData.error ? ' login__container--error' : '')}>
				<img alt='' className='login__image' src='moss.jpg' />
				<form className='login__form' >
					<input className='login__input' type='text' name='id' value={formData.id} readOnly/>
					<input placeholder='password' className='login__input' type='password' name='password' value={formData.password} onChange={formUpdate} />	
					<input className='login__submit' type='submit' value='Log in' onClick={submit} />
				</form>
			</div>
		</div>
	);
	
}

export default Login;