import './file.css';

import { fileSystemIcons } from '../fs';

const File = (props) => {
	return (
		<div 
			className='file'
			onClick={props.openWindow}
			data-fileid={props.fileid} >
			<div className='file__icon'>{
				props.data.icon ? 
					props.data.icon
				:
					fileSystemIcons[props.data.type.name]
			}</div>
			<span className='file__name'>{props.data.name}</span>
		</div>
	);
}

export default File;