import { useEffect, useState } from 'react';

import './desktop.css';

import Window from './window';
import { fileSystem } from '../fs';
import File from './file';

const Date = () => {

	const [date, setDate] = useState(new window.Date());
	
	useEffect(() => {
		setInterval(() => setDate(new window.Date()), 1000)
	}, []);

	return (<p className='date'> {
		date.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
	}</p>);

}

const Desktop = (props) => {

	const [openedWindows, setOpenedWindows] = useState([]);

	const openWindow = (e) => {
		const fileid = e.currentTarget.dataset.fileid;
		const fileids = fileid.split('_');
		var newWindow = fileSystem;
		fileids.forEach(fileid => {
			newWindow = newWindow['children'][fileid];
		});
		newWindow.fileid = fileid;
		setOpenedWindows(openedWindows => [...openedWindows, newWindow]);
	}

	const closeWindow = (e) => {
		const closedwindowid = parseInt(e.currentTarget.closest('.window').dataset.windowid);
		setOpenedWindows(openedWindows => openedWindows.filter((openedWindow, index) => {
			if (closedwindowid !== index)
				return openedWindow;
		}));
	}

	return (
	<div className='desktop'>
		<div className='menubar'>
			<span
				className='menubar__logo'
				onClick={props.closeDesktop}>
				♥
			</span>
			<Date /></div>
		<div className='desktop-content' >
			{
				fileSystem['children'].map((file, index) => (
					<File
						openWindow={openWindow}
						key={file.name + index.toString()}
						fileid={index.toString()}
						data={file} />
				))
			}
			{
				openedWindows.map((openedWindow, index) => (
					<Window
						closeWindow={closeWindow}
						openWindow={openWindow}
						windowid={index}
						parentposition={{
							left: (index % 15) * 10,
							top: (index % 15) * 10
						}}
						fileid={openedWindow.fileid}
						key={openedWindow.name + index.toString()}
						data={openedWindow} />
				))
			}
		</div>
	</div>);
}

export default Desktop;