import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import Login from './components/login';
import Desktop from './components/desktop';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {

	const [isUserLogon, setIsUserLogon] = useState(false);
	
	const closeLogin = () => {
		setIsUserLogon(true);
	}
	
	const closeDesktop = () => {
		setIsUserLogon(false);
	}
	
	return (
		<React.StrictMode>
			<div className='background' >
				{ isUserLogon ? <Desktop closeDesktop={closeDesktop} /> : <Login closeLogin={closeLogin} /> }
			</div>
		</React.StrictMode>
	);
}

root.render(<App />);