import './text.css';

const Text = (props) => {
	return (<div className='text-body'>
		{
			props.data.paragraphes.map((paragraph, index) => (
				<p
					className={'text' + (paragraph.className ? ' ' + paragraph.className : '' )}
					key={index.toString()}>
					{paragraph.content}
				</p>
			))
		}
	</div>);
}

export default Text;