import { useState } from 'react';

import './window.css';

const Window = (props) => {

	const [position, setPosition] = useState({
		current: {
			top: props.parentposition.top + 10,
			left: props.parentposition.left + 10 
		},
		start: {top: 0, left: 0},
		isMoving: false
	});

	const grab = (e) => {
		setPosition(position => ({
			...position,
			isMoving: true,
			start: {
				left: e.pageX - position.current.left,
				top: e.pageY - position.current.top
			}
		})); 
	}
	
	const drop = () => {
		if (position.isMoving)
			setPosition(position => ({...position, isMoving: false}));
	}

	const drag = (e) => {
		if (!position.isMoving)
			return ;
		setPosition(position => ({
			...position,
			current: {
				left: e.pageX - position.start.left,
				top: e.pageY - position.start.top
			}
		}))
	}

	return (

		<div
			className='window'
			data-windowid={props.windowid}
			style={{top: position.current.top + 'px', left: position.current.left + 'px'}}>
			<div
				className={'window__header ' + (position.isMoving ? 'window--moving' : 'window--notmoving')}
				onMouseDown={grab}
				onMouseUp={drop}
				onMouseLeave={drop}
				onMouseMove={drag}>
				<p className='header__name'>{props.data.name}</p>
				<input
					className='header__close'
					type='text' value='×' readOnly
					onClick={props.closeWindow} />
			</div>
			<div className='window__body'>
				<props.data.type
					data={props.data}
					parentfileid={props.fileid}
					openWindow={props.openWindow}
					closeWindow={props.closeWindow} />
			</div>
		</div>

	);

}

export default Window;