import Folder from './components/folder';
import Image from './components/image';
import Video from './components/video';
import Text from './components/text'

const fileSystemIcons = {
	'Folder': 'folder_open',
	'Image': 'image',
	'Video': 'videocam',
	'Text': 'description'
}

const fs2 = [
	{
		type: Folder, icon: 'folder_open',
		name: 'here we go',
		children: [
			{
				type: Folder, icon: 'folder_open',
				name: 'xmas is inside',
				children: [
					{
						type: Video,
						name: 'XMAS',
						icon: 'redeem',
						src: 'ct.mp4'
					}
				]
			}
		]
	}
]

const fs1 = [{
	type: Folder, icon: 'folder_open',
	name: 'Do you love me ?',
	children: [
		{
			type: Folder, icon: 'folder_open',
			name: 'Yes',
			children: [
				{
					type: Folder, icon: 'folder_open',
					name: 'Awww',
					children: [
						{
							type: Folder, icon: 'folder_open',
							name: 'you swear ?',
							children: [
								{
									type: Folder, icon: 'folder_open',
									name: 'yes',
									children: [
										{
											type: Folder, icon: 'folder_open',
											name: 'yes',
											children: [
												{
													type: Folder, icon: 'folder_open',
													name: 'yes',
													children: [
														{
															type: Folder, icon: 'folder_open',
															name: 'yes',
															children: [
																{
																	type: Folder, icon: 'folder_open',
																	name: 'yes',
																	children: [
																		{
																			type: Folder, icon: 'folder_open',
																			name: 'yes',
																			children: [
																				{
																					type: Folder, icon: 'folder_open',
																					name: 'yes',
																					children: [
																						{
																							type: Folder, icon: 'folder_open',
																							name: 'yes',
																							children: [
																								{
																									type: Folder, icon: 'folder_open',
																									name: 'yes',
																									children: [
																										{
																											type: Folder, icon: 'folder_open',
																											name: 'yes',
																											children: [
																												{
																													type: Folder, icon: 'folder_open',
																													name: 'yeeeeeeeeeeeeees',
																													children: [
																														{
																															type: Folder, icon: 'folder_open',
																															name: 'yeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeees',
																															children: [
																																{
																																	type: Folder, icon: 'folder_open',
																																	name: 'geez ok i get it',
																																	children: fs2
																																}
																															]
																														}
																													]
																												}
																											]
																										}
																									]
																								}
																							]
																						}
																					]
																				}
																			]
																		}
																	]
																}
															]
														}
													]
												}
											]
										}
									]
								}
							]
						}
					]
				}
			]
		}
	]
}]

const fileSystem = {
	children: [
		{
			type: Folder,
			name: 'Downloads',
			icon: 'download',
			children: [
				{
					type: Image, icon: 'image',
					name: 'castlemania.jpg',
					src: 'download/castle.jpg'
				},
				{
					type: Image, icon: 'image',
					name: 'paradise',
					src: 'download/cans.jpg'
				}
			]
		},
		{
			type: Folder, icon: 'folder_open',
			name: 'Documents',
			children: [
				{
					type: Text, icon: 'description',
					name: 'xmas wish list',
					paragraphes: [
						{content: '- a mouse furry coat'},
						{content: '- a feathered hat :D'},
						{content: '- cinamon + tuna tastes treat'},
						{content: '- cat wine'}
					]
				},
				{
					type: Text, icon: 'description',
					name: 'todo list',
					paragraphes: [
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow"},
						{content: "- URGENT --> Meow !"},
						{content: "- Meow"},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meoooooooooow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow lol XD"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow Meow "},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow (?)"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow Meow Meow Meow! "},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow"},
						{content: "- Meow -", className: 'text--skriked'},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"},
						{content: "- Meow"}
					]
				},
				{
					type: Folder, icon: 'folder_open',
					name: 'poems',
					children: [
						{
							type: Text, icon: 'description',
							name: 'poem',
							paragraphes: [
								{content: 'Tuna is good,'},
								{content: 'Mouses too,'},
								{content: 'Where are thou ?'}
							]
						},
						{
							type: Text, icon: 'description',
							name: 'poem 2',
							paragraphes: [
								{content: 'If you can eat all your tuna cans'},
								{content: 'And never throwing up,'},
								{content: 'If you can be loved and fed everyday'},
								{content: 'Without ever showing any interest for your masters,'},
								{content: '', className: 'text--CRLF'},
								{content: 'If you can catch a mouse or bird in flight,'},
								{content: 'And bring it home as a gift to your home,'},
								{content: 'If you can strut with pride and grace and style'},
								{content: 'And make your presence known with a flick of your tail,'},
								{content: '', className: 'text--CRLF'},
								{content: 'If you can keep your independence and roam'},
								{content: 'But always come back home without fail,'},
								{content: 'If you can always be at warm'},
								{content: 'But always being cold with everyone'},
								{content: '', className: 'text--CRLF'},
								{content: 'Yours is the Earth and every cardboard castles that\'s in it'},
								{content: 'And —which is more— you\'ll be a Cat, my son!'}
							]
						}
					]
				},
				{
					type: Text, icon: 'description',
					name: 'dreams',
					paragraphes: [
						{content: '12/20/2022: Crazy dream ! It was all miawing everywhere lol ! I wish I could dream it one more time !'},
						{content: '-----'},
						{content: '12/2/2022: there was a bird trying to eat me ! I ran and ran and ran but it kept reaching me !'},
						{content: '-----'},
						{content: '11/7/2022: I dreamt that I ran away and entered into a grocery store. There was a nice man, he gaves me tuna :) but then mom came to take me back home but I was happy tho...'},
						{content: '-----'},
						{content: '10/7/2022: I dream of carrot top ! he was taking thinkgs from out of my castle it was surprising tbh but i didn\'t trust him because of his weird hair color...'},
						{content: '-----'},
						{content: '10/15/2022: Emery was standing on the table in the living room, I told him he was not allowed ! but he didn\'t listen ! and then mom came ! and she was scolding him "bad boy!" and so he jumped everywhere so funny'},
						{content: '-----'},
						{content: '10/4/2022: I was on the patio but the door was closed ! But dad came to save me and opened the door, I was happy but didn\'t want to show any gratitude so I decided to stay outside'},
						{content: '-----'}
					]
				},
				{
					type: Text, icon: 'description',
					name: 'meow ?',
					paragraphes: [
						{content: 'MEOW !'}
					]
				}
			]
		},
		{
			type: Folder, icon: 'folder_open',
			name: 'Images',
			children: [
				{
					type: Folder, icon: 'folder_open',
					name: 'Me',
					children: [
						{
							type: Image, icon: 'image',
							name: 'stretching lol',
							src: 'me/stretching lol.jpg'
						},
						{
							type: Image, icon: 'image',
							name: 'Me.jpg',
							src: 'me/king.jpg'
						},
						{
							type: Image, icon: 'image',
							name: 'coy',
							src: 'me/im_shy_lol.jpg'
						},
						{
							type: Folder, icon: 'folder_open',
							name: 'things',
							children: [
								{
									type: Folder, icon: 'folder_open',
									name: 'stuffs',
									children: [
										{
											type: Folder, icon: 'folder_open',
											name: ' ',
											children: [
												{
													type: Folder, icon: 'folder_open',
													name: '???',
													children: [
														{
															type: Folder, icon: 'folder_open',
															name: '3 fd 55b 7 ss ka fff',
															children: [
																{
																	type: Folder, icon: 'folder_open',
																	name: '[]f0fsd fdsfds',
																	children:[
																		{
																			type: Folder, icon: 'folder_open',
																			name: '=3=r-=++3r',
																			children: [
																				{
																					type: Folder, icon: 'folder_open',
																					name: 'p003dda_ d ',
																					children: [
																						{
																							type: Folder, icon: 'folder_open',
																							name: 'com\'on',
																							children: [
																								{
																									type: Folder, icon: 'folder_open',
																									name: 'WHAT ARE YOU DOING ?',
																									children: [
																										{
																											type: Folder, icon: 'folder_open',
																											name: 'STOP !',
																											children: [
																												{
																													type: Folder, icon: 'folder_open',
																													name: 'Ok I see',
																													children: [
																														{
																															type: Folder, icon: 'folder_open',
																															name: 'This one ?',
																															children: [
																																{
																																	type: Folder, icon: 'folder_open',
																																	name: 'are you sure ?',
																																	children: [
																																		{
																																			type: Folder, icon: 'folder_open',
																																			name: 'Well maybe ok',
																																			children: fs1
																																		}
																																	]
																																}
																															]
																														},
																														{
																															type: Folder, icon: 'folder_open',
																															name: 'Or this one :) ?',
																															children: [
																																{
																																	type: Text, icon: 'description',
																																	name: 'nope',
																																	paragraphes: [
																																		{content: 'meh... maybe in the other one ?'}
																																	]
																																}
																															]
																														}
																													]
																												}
																											]
																										}
																									]
																								}
																							]
																						}
																					]
																				}
																			]
																		}
																	]
																}
															]
														}
													]
												}
											]
										}
									]
								}
							]
						}
					]
				},
				{
					type: Folder, icon: 'folder_open',
					name: 'food',
					children : [
						{
							type: Image, icon: 'image',
							name: 'fish 0001',
							src: 'food/fish.jpg'
						},
						{
							type: Video, icon: 'videocam',
							name: 'birdy bird <3.mp4',
							src: 'food/bird.mp4'
						},
						{
							type: Image, icon: 'image',
							name: 'fish 0002',
							src: 'food/tuna3.jpg'
						},
						{
							type: Image, icon: 'image',
							name: 'box of 50!',
							src: 'food/50.jpg'
						}
					]
				}
			]
		},
		{
			type: Folder,
			name: 'Trash bin',
			icon: 'delete',
			children: [
				{
					type: Text, icon: 'description',
					name: 'LAME AND BORING todo list.txt',
					paragraphes: [
						{ content: '1/ being nice lol' }
					]
				},
				{
					type: Image, icon: 'image',
					name: 'empty :<',
					src: 'trash/emptycans.jpg'
				}
			]
		}
	]
};

export { fileSystem, fileSystemIcons };