import File from './file';

import './folder.css';

const Folder = (props) => {
	
	return (

		<div className='folder__body' >
			{
				props.data.children.map((child, index) => (
					<File
						key={child.name + index.toString()}
						fileid={props.parentfileid + '_' + index.toString()}
						openWindow={props.openWindow}
						closeWindow={props.closeWindow}
						parentposition={props.parentposition}
						data={child} />
				))
			}
		</div>

	);

}

export default Folder;