import './image.css';

const Image = (props) => {
	return (
		<div className='image__body'>
			<img className='image__img' alt='' src={props.data.src} />
		</div>
	);
}

export default Image;